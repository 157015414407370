(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/data-attribute-observer/assets/javascripts/data-attribute-observer.js') >= 0) return;  svs.modules.push('/components/components/data-attribute-observer/assets/javascripts/data-attribute-observer.js');
"use strict";


svs.components = svs.components || {};
svs.components.dataAttribute = svs.components.dataAttribute || {};
const INTERSECTION_RATIO = 0.5;
const TIME_TO_IMPRESSION = 1000;
const intersectionObserverOptions = {
  root: null,
  rootMargin: '0px',
  threshold: [INTERSECTION_RATIO]
};
const logger = svs.core.log.getLogger('components:data-attribute-observer');
let started = false;
const visualElements = new Set(); 
const attributeMap = new Map(); 

let intersectionObserver;
let mutationObserver;
const isArrayLike = obj => Array.isArray(obj) || Boolean(obj) === true && typeof obj === 'object' && typeof obj.length === 'number' && (obj.length === 0 || obj.length > 0 && obj.length - 1 in obj);
const mutationObserverCallback = mutationList => {
  mutationList.forEach(mutation => {
    if (mutation.type === 'childList' && isArrayLike(mutation.addedNodes)) {
      Array.prototype.forEach.call(mutation.addedNodes, node => {
        for (const attribute of attributeMap.keys()) {
          if (typeof node.hasAttribute === 'function' && node.hasAttribute(attribute)) {
            intersectionObserver.observe(node);
          }
          if (typeof node.querySelectorAll === 'function') {
            const dataImpressionElements = node.querySelectorAll("[".concat(attribute, "]"));
            for (let i = 0; i < dataImpressionElements.length; i++) {
              intersectionObserver.observe(dataImpressionElements[i]);
            }
          }
        }
      });
    }
  });
};
const sendImpression = element => {
  for (const attribute of attributeMap.keys()) {
    if (!element.hasAttribute(attribute)) {
      continue;
    }
    const cb = attributeMap.get(attribute);
    cb(element);
    intersectionObserver.unobserve(element);
    const value = element.getAttribute(attribute);
    element.removeAttribute(attribute);
    element.setAttribute("".concat(attribute, "-tracked"), value);
  }
};
const timeInViewport = element => setTimeout(() => {
  if (visualElements.has(element)) {
    sendImpression(element);
    visualElements.delete(element);
  }
}, TIME_TO_IMPRESSION);
const handleIntersection = entries => {
  entries.forEach(entry => {
    const el = entry.target;
    if (entry.isIntersecting && entry.intersectionRatio >= INTERSECTION_RATIO) {
      visualElements.add(el);
      timeInViewport(el);
    } else {
      visualElements.delete(el);
    }
  });
};
const updateMutationObserver = () => {
  if (mutationObserver) {
    mutationObserver.disconnect();
  }
  const targetNode = document.querySelector('body');
  const mutationObserverOptions = {
    attributes: true,
    attributeFilter: [],
    childList: true,
    subtree: true
  };
  for (const attribute of attributeMap.keys()) {
    mutationObserverOptions.attributeFilter.push(attribute);
  }
  mutationObserver = new MutationObserver(mutationObserverCallback);
  mutationObserver.observe(targetNode, mutationObserverOptions);
};
const trackAttribute = attribute => {
  try {
    let trackedDatasetName = attribute.replace(/^data-/, '');
    trackedDatasetName = trackedDatasetName.replace(/-./g, x => x.toUpperCase()[1]); 
    trackedDatasetName = "".concat(trackedDatasetName, "Tracked");
    let elementsToTrack = [...document.querySelectorAll("[".concat(attribute, "]"))];
    elementsToTrack = elementsToTrack.filter(el => el.dataset[trackedDatasetName] === undefined); 
    elementsToTrack.forEach(el => intersectionObserver.observe(el));
  } catch (e) {
    logger.warn("Unexpected error:".concat(e.message));
  }
};
const updateImpressionTracking = () => {
  started = true;
  if (intersectionObserver) {
    intersectionObserver.disconnect();
  }
  intersectionObserver = new IntersectionObserver(handleIntersection, intersectionObserverOptions);
  for (const attribute of attributeMap.keys()) {
    trackAttribute(attribute);
  }
  updateMutationObserver();
};
svs.components.dataAttribute.track = (attribute, cb) => {
  if (attributeMap.has(attribute)) {
    return;
  }
  attributeMap.set(attribute, cb);
  if (started) {
    updateImpressionTracking();
  }
};
window.addEventListener('load', updateImpressionTracking);

 })(window);